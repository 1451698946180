import React, { useState } from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import {
  Tabs,
  Tab,
  Box,
  Container,
  Hidden,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import { H3 } from '@system'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => {
  return {
    title: {
      color: theme.palette.secondary.main,
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightLight,
      paddingBottom: '40px',
      margin: 0,
    },
    root: {
      margin: 0,

      fontSize: 16,
      color: theme.palette.text.tertiary,
      justifyContent: 'space-between',

      '& .MuiTab-wrapper': {
        height: '100%',
        zIndex: '100',
      },
    },
    tab: {
      height: 48,
      display: 'flex',
    },
  }
})

const useTabsStyles = makeStyles((theme) => {
  return {
    root: {
      height: 48,
      display: 'flex',
      alignItems: 'center',
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: 5,
    },
  }
})

const useSelectStyles = makeStyles((theme) => {
  return {
    root: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    formControl: {
      width: '100%',
      padding: '12px 0px 6px',
    },
    select: {
      backgroundColor: theme.palette.secondary.main,
      width: '100%',
      '&:after': {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
      },
      '& svg': {
        fontSize: '1.5rem',
        color: theme.palette.text.white,
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.secondary.main,
        },
      },
    },
    selectInput: {
      height: '40px',
      '&>div': {
        color: theme.palette.text.white,
        fontSize: '14px',
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
  }
})

const ToolTab = ({ isLink, ...props }) => {
  const classes = useStyles()
  return (
    <Box>
      <Tab
        component={'span'}
        className={classes.tab}
        classes={{ root: classes.root }}
        onClick={(event) => {
          event.preventDefault()
        }}
        {...props}
      >
        {props.children}
      </Tab>
    </Box>
  )
}

const TabCarousel = (props) => {
  const [value, setValue] = useState(0)
  const classes = useStyles()
  const tabsClasses = useTabsStyles()
  const selectClasses = useSelectStyles()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleSelect = (event) => {
    setValue(event.target.value)
  }

  return (
    <SbEditable content={props.blok}>
      <Box>
        <PageContainer>
          <ContentContainer>
            <H3 className={classes.title}>{props.blok.title}</H3>
          </ContentContainer>
        </PageContainer>
        <Container className={selectClasses.root}>
          <FormControl variant="filled" className={selectClasses.select}>
            <Select
              value={value}
              onChange={handleSelect}
              className={selectClasses.selectInput}
            >
              {props.blok.tabs &&
                props.blok.tabs.map((tab, index) => {
                  return (
                    <MenuItem key={tab._uid} value={index}>
                      {tab.content}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Container>
        <PageContainer>
          <Hidden smDown>
            <Box maxWidth={1056} mx="auto" width="100%" height={48} px={4.2}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="feature tabs"
                variant="fullWidth"
                centered
                classes={{
                  root: tabsClasses.root,
                  indicator: tabsClasses.indicator,
                }}
              >
                {props.blok.tabs &&
                  props.blok.tabs.map((tab, index) => {
                    return <ToolTab key={tab._uid} label={tab.content} />
                  })}
              </Tabs>
            </Box>
          </Hidden>
        </PageContainer>
        <PageContainer>{renderBlok(props.blok.content[value])}</PageContainer>
      </Box>
    </SbEditable>
  )
}

export default TabCarousel
